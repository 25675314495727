<template>
	<Offer>
		<template #banner>
			<img slot="banner" v-if="mobile === false" src="../../../../../assets/images/banners/unions.png" alt="banner">
			<img slot="banner" v-else src="../../../../../assets/images/mobile/unions.png" alt="banner">
		</template>

		<template #button>
			<i v-if="mobile === true" class="fas fa-arrow-left"></i>
			<router-link slot="button" to="/offer">Wróć do spisu usług</router-link>
		</template>

		<template #small-title>
			<h4>PRAWO PRACY</h4>
		</template>

		<template #title>
			<h2>Pomoc prawna w relacji
				Pracodawca – Związki Zawodowe</h2>
		</template>

		<template #text>
			<p>Obszar pomocy prawnej w zakresie relacji Pracodawca – Związki zawodowe obejmuje między innymi
				usługi w zakresie:</p>
		</template>

		<template #range>
			<h3>Zakres:</h3>
		</template>
		<template #list>
			<ul class="offer__list">
				<li class="offer__list-item">opracowywania strategii postępowania w relacjach ze Związkami Zawodowymi;
				</li>
				<li class="offer__list-item">doradztwa i wsparcia w zakresie opiniowania, tworzenia i dostosowania
					regulaminów organizacyjnych i regulaminów stanowiących źródła prawa pracy;
				</li>
				<li class="offer__list-item">przeprowadzania całego procesu wprowadzania nowego zakładowego układu
					zbiorowego pracy, umów społecznych lub innego rodzaju porozumień zbiorowych, takich jak pakty
					gwarancji pracowniczych czy programy dobrowolnych odejść (PDO);
				</li>
				<li class="offer__list-item">przeprowadzania sporów zbiorowych lub odmowy ich uznania.</li>

			</ul>
		</template>
	</Offer>
</template>

<script>
import Offer from '../Offer'
import {handleWith} from "@/mixins/globalMixins";

export default {
	mixins: [handleWith],
	components: {
		Offer
	},
	name: 'unions',
}
</script>